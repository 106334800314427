<template>
  <div>
    <!-- <v-toolbar dark color="primary">
      <v-toolbar-title class="white--text">勤怠管理システム</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar> -->
    <v-layout class="mt-5 pa-5">
      <v-flex xs3></v-flex>
      <v-flex xs6>
        <v-card class="ma-5">
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                label="email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="パスワード"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="login()" color="primary">login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs3></v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    login () {
      let postData = {
        email: this.email,
        password: this.password
      }
      axios.post('/api/manager/login', postData)
        .then((res) => {
          localStorage.setItem('isRecordBodyTemperature', res.data.is_record_body_temperature)
          localStorage.setItem('toolbarTitle', res.data.toolbar_title)
          localStorage.setItem('companyName', res.data.name)
          if (res.data.managers) {
            localStorage.setItem('managers', JSON.stringify(res.data.managers))
          }
          if (this.email === 'ironworks-l@asano.itc-easier.jp') {
            this.$store.commit('setLimitManager', true)
            document.cookie = 'isLimitManager=1'
          } else if (this.email === 'forge-l@asano.itc-easier.jp') {
            this.$store.commit('setLimitManager', true)
            document.cookie = 'isLimitManager=1'
          } else {
            this.$store.commit('setLimitManager', false)
            document.cookie = 'isLimitManager=0'
          }
          // this.$router.push('/manager/default')
          location.href = '/manager-list'
        })
        .catch(() => {
          alert('認証エラー')
        })
    }
  }
}
</script>
