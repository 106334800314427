<template>
  <v-toolbar dark color="primary">
    <!-- <img src="/itc-logo.png" style="width: 3em"> -->
    <v-btn flat @click="$router.push('/manager/default')">{{ toolbarTitle }}</v-btn>

    <v-spacer></v-spacer>

    <v-btn flat @click="$router.push('/manager/default')">
      お知らせ
    </v-btn>

    <v-btn flat @click="$router.push('/manager/worktime-record')">
      日別データ
    </v-btn>

    <v-btn flat @click="toManagerList">
      一覧データ
    </v-btn>

    <v-btn flat @click="toManagerTotal">
      集計データ
    </v-btn>

    <v-btn v-if="fullManager" flat @click="$router.push('/manager/overtime-work')">
      時間外勤務申請
    </v-btn>

    <v-btn flat @click="$router.push('/manager/leave-application')">
      休暇・申請
    </v-btn>

    <v-btn v-if="fullManager" flat @click="$router.push('/manager/holiday')">
      休日設定
    </v-btn>

    <v-btn v-if="fullManager" flat @click="$router.push('/manager/staff')">
      スタッフ
    </v-btn>

    <div style="width: 200px; overflow: hidden;">
      <v-select
        v-if="managers"
        v-model="selectedManager"
        :items="managers"
        item-text="company"
        item-value="id"
        @change="changeManager"
      ></v-select>
    </div>

    <!-- <v-btn flat @click="logout">
      logout
    </v-btn> -->
    <!-- <v-icon class="ml-2" @click="logout">code</v-icon> -->
    <img src="../../logout.png" @click="logout" style="margin-left: 1em; width: 1.5em">
  </v-toolbar>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    toolbarTitle: '',
    managers: null,
    selectedManager: ''
  }),
  computed: {
    fullManager () {
      let result = true
      if (this.$store.state.isLimitManager) {
        result = false
      }
      return result
    }
  },
  methods: {
    logout () {
      axios.post('/api/manager/logout')
        .then(() => {
          localStorage.clear()
          this.$router.push('/manager')
        })
        .catch(() => {
          alert('通信エラー')
        })
    },
    toManagerList () {
      window.location = '/manager-list'
    },
    toManagerTotal () {
      window.location = '/manager-total'
    },
    async changeManager () {
      if (this.selectedManager === '') {
        return
      }
      const postOption = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: this.selectedManager })
      }
      const res = await fetch('/api/manager/ShoSh8deUiriin0ashi0Aere', postOption)
      const data = await res.json()
      if (!res.ok) {
        alert('fetch error.')
      } else {
        localStorage.setItem('isRecordBodyTemperature', data.is_record_body_temperature)
        localStorage.setItem('toolbarTitle', data.toolbar_title)
        localStorage.setItem('companyName', data.name)
        // window.location.href = '/manager-list';
        window.location.reload()
      }
    }
  },
  mounted () {
    this.managers = localStorage.getItem('managers')
    if (this.managers) {
      this.managers = [ { id: '', company: 'マネージャー変更' }, ...JSON.parse(this.managers) ]
    }
    this.toolbarTitle = localStorage.getItem('toolbarTitle')
    let isLimitManager = false
    document.cookie.split(';').forEach((cookieStr) => {
      let [k, v] = cookieStr.split('=')
      if (k) {
        k = k.trim()
        v = v.trim()
        if (k === 'isLimitManager') {
          if (v === '1') {
            isLimitManager = true
          }
        }
      }
    })
    this.$store.commit('setLimitManager', isLimitManager)
    this.toolbarTitle = localStorage.getItem('toolbarTitle')
  }
}
</script>
